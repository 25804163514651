import React from "react"
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { BlockRenderer, Grid, Picture, Margin, PageWrapper, Section, Underlined, ReturnLink } from '@components/atoms'
import { GridItemScrollStrip, GridItem, Tags, ProjectNavigation } from '@components/molecules'
import { Description, ProjectTitle  } from '@components/molecules/GridItem'
import { ScrollStripGridItem } from '@components/molecules/GridItemScrollStrip'
import { TransitionMask } from '@components/organisms'
import { WithData } from '@utils'
import { palette } from '@theme'
import { ProjectProps } from '@types'



const ProjectTemplate = ({ 
  pageContext: {
    settings,
  },
  data: {
    sanityPost: { 
      postType, 
      title, 
      _rawIntroduction,
      description,
      heroType,
      heroImageConditional,
      heroVideoConditional,
      shareImage,
      extraProjects,
      gridItems,
      tags,
      ctaLink,
      ctaCopy,
    },
  },
}: ProjectProps): React.ReactElement => {
  const projectSettings = {
    ...settings,
    subtitle: title,
    section: (postType === 'work') ? 'work' : (postType === 'job') ? 'jobs' : 'play',
    shareImage: shareImage || settings.shareImage,
    keywords: tags ? tags.map(tag => tag.tag).join(', ') : settings.keywords,
    description: description || settings.description,
  }
  
  const projectNav = extraProjects?.projectNavigation ? <ProjectNavigation links={extraProjects?.projectNavigation} /> : undefined
  /* Note to self:
     ProjectNav anchors are currently a property of GridItemCaption.
     Probably should have been on GridItem, could refactor and 
       move presentation from GridItemCaption to GridItem ¯\_(ツ)_/¯ 
  */
  
  const parsedTitle = 'Franklyn: ' + title
  const meta = [
    { property: "og:title", content: parsedTitle },
    { name: "twitter:title", content: parsedTitle },
    { name: "description", content: description },
    { name: "twitter:description", content: description },
    { property: "og:description", content: description },
    { property: "og:image", content:  `${shareImage?.asset.url}?w=640&h=640&fm=jpg&q=75` },
    { name: "twitter:image", content:  `${shareImage?.asset.url}?w=640&h=640&fm=jpg&q=75` },
  ]

  return (
    <PageWrapper hasHero={heroType && heroType !== 'none'} >
      <Helmet {...{meta}} title={parsedTitle} >
        <title>{parsedTitle}</title>
      </Helmet>
      
      {heroType && heroType !== 'none' && (
        <Hero>
          {heroImageConditional && (
            <HeroImage src={heroImageConditional?.heroImage.asset.url} dimensions={heroImageConditional?.heroImage.asset.metadata.dimensions}/>
          )}
          {heroVideoConditional && (
            <HeroVideo autoPlay muted loop playsInline >
              <source src={heroVideoConditional?.heroVideo.asset.url} />
            </HeroVideo>
          )}        
        </Hero>
      )}
      
      <Margin>
        <Grid noPadding >
          {projectSettings.section === 'work' && (
            <TitleBlock>
              <Title>
                <Underlined>{title}</Underlined>
              </Title>
            </TitleBlock>
          )}
          {projectSettings.section === 'jobs' && (
            <TitleBlock>
              <Underlined>Careers</Underlined>
              <SecondLine>
                <Category>{title}</Category>
              </SecondLine>
            </TitleBlock>
          )}
          {projectSettings.section !== 'work' && projectSettings.section !== 'jobs' && (
            <TitleBlock>
              <Underlined>Play</Underlined>
              <SecondLine>
                <Category>{postType}</Category>
                {postType !== 'thoughts' && (<Title>{title}</Title>)}
              </SecondLine>
            </TitleBlock>
          )}          
          <Introduction marginTop={projectSettings.section !== 'work'}>
            {extraProjects?.projectNavigation && extraProjects?.projectNavigation.length > 0 && projectNav !== undefined &&  (
              React.cloneElement(projectNav, { activeItem: 'top' })
            )}
            {_rawIntroduction && (
              <BlockRenderer>{_rawIntroduction}</BlockRenderer>
            )}
            {tags && (<StyledTags {...{ tags }} />)}
          </Introduction>
        </Grid>
      </Margin>
      
      <Margin>
        <StyledGrid>
          {gridItems.filter(p => !!p).map((item) => (
            <GridItem 
              key={item._key} 
              {...item} 
              {...{projectNav}}
            />
          ))}
        </StyledGrid>
      </Margin>
      
      {ctaCopy && ctaLink && (
        <ReturnLink to={`/` + ctaLink.slug.current} >{ctaCopy}</ReturnLink>
      )}
      
      {extraProjects?.moreProjects && extraProjects?.moreProjects.length > 0 && (
        <Margin black>
          <Section title="Related Projects" >
            <StyledGridItemScrollStrip >
              {extraProjects?.moreProjects.filter(p => !!p).map((item, i) => (
                <ScrollStripGridItem 
                  {...item} 
                  key={i}
                  _type="gridItem"
                  columns={item.layout?.layout?.columns || 6} 
                  mobileColumns={item.layout?.layout?.mobileColumns || 3}
                >
                  <ProjectTitle>{item.post?.title}</ProjectTitle>
                  <Description>{item.post?.description}</Description>
                </ScrollStripGridItem>
              ))}
            </StyledGridItemScrollStrip>
          </Section>
        </Margin>
      )}
      
      <TransitionMask />
    </PageWrapper>
  )
}



const StyledGridItemScrollStrip = styled(props => <GridItemScrollStrip {...props} />)`
  padding-top: 0 !important;
  padding-bottom: 0 !important;
`


const Hero = styled.div`
  margin-bottom: 60px;
  @media only screen and (min-width: 744px) {
    margin-bottom: 90px;
  }
  @media only screen and (min-width: 1024px) {
    margin-bottom: 120px;
  }
`


const HeroImage = styled(props => <Picture {...props} />)`
  width: 100%;
  height: auto;
`


const HeroVideo = styled.video`
  width: 100%;
  height: auto;
`


const TitleBlock = styled.header`
  grid-column: span 6;
  @media only screen and (max-width: 743px) {
    font-size: 2.1rem;    
  }
  @media only screen and (min-width: 744px) {
    grid-column: span 4;
  }
`

const SecondLine = styled.div`
  margin-top: 1em;
  > * {
    display: inline-block;
  }
`

const Category = styled.div`
  display: inline-block;
  margin-right: 25px;
  text-transform: capitalize;
  color: ${palette.lime};
`

const StyledGrid = styled(props => <Grid {...props} />)`
  @media only screen and (max-width: 743px) {
    grid-row-gap: 30px;
  }
`

const Title = styled.h1``

const Introduction = styled.div<{marginTop: boolean}>`
  grid-column: span 6;
  padding: 20px 0 30px 0;
  @media only screen and (min-width: 744px) {
    padding: 0;
    ${props => props.marginTop && `margin-top: 50px;`}
    grid-column: span 7;
  }
`

const StyledTags = styled(props => <Tags {...props} />)`
  margin-top: 1em;
  @media only screen and (min-width: 744px) {
    margin-top: 0.5em;
  }
`



const mapDataToProps = ({ sanityPost }: any) => ({ ...sanityPost })


export const query = graphql`
  query project($slug: String!) {
    sanityPost(slug: {current: {eq: $slug}}) {
      slug {
        current
      }
      title
      postType
      description
      heroType
      heroImageConditional {
        heroImage {
          asset {
            url
            metadata {
              dimensions {
                width
                height
                aspectRatio
              }
            }
          }
        }
      }
      heroVideoConditional {
        heroVideo {
          asset {
            url
          }
        }
      }
      shareImage {
        asset {
          url
        }
      }
      _rawIntroduction
      tags {
        tag
      }
      gridItems {
        ... on SanityGridItem {
          ...gridItemFields
        }
        ... on SanityItemCredits {
          ...gridItemCreditsFields
        }
        ... on SanityGridItemStrip {
          ...gridItemStripFields
        }
      }
      ctaLink {
        ... on SanityPage {
          slug {
            current
          }
        }
        ... on SanityPost {
          slug {
            current
          }
        }
      }
      ctaCopy
      extraProjects {
        moreProjects {
          
          layout {
            layout {
              columns
              mobileColumns
            }  
          }
          
          link
          post {
            title
            description
            slug {
              current
            }
          }
          
          gridItemTypeSwitch {
            gridItemType
            
            gridItemCaption {
              _type
              _rawCaption
              anchor
            }
            
            gridItemImage {
              _type
              image {
                asset {
                  url
                  metadata {
                    dimensions {
                      width
                      height
                      aspectRatio
                    }
                  }
                }
              }
              mobileImage {
                asset {
                  url
                  metadata {
                    dimensions {
                      width
                      height
                      aspectRatio
                    }
                  }
                }
              }
            }
            
            gridItemVideo {
              _type
              video {
                asset {
                  url
                }
              }
              mobileVideo {
                asset {
                  url
                }
              }
            }
          }
        }
        
        projectNavigation
      }
    }
  }
`

export default WithData(mapDataToProps, ProjectTemplate)